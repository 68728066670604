@use "sass:math";

// LES TITRES ----------
// Très Gros titre (h1)
.c-huge-title {
    font-size: var(--fz-60);
    font-weight: 700;
    line-height: 1.3em;
}

// Gros titre (h2)
.c-large-title {
    font-size: var(--fz-40);
    font-weight: 700;
    line-height: 1.3em;
}

// Moyen titre (h3)
.c-medium-title {
    font-size: var(--fz-30);
    font-weight: 700;
    line-height: 1.3em;
}

// Petit titre (h4)
.c-small-title {
    font-size: var(--fz-18);
    font-weight: 500;
    line-height: 1.3em;
}


// LES TEXTES ----------
// Le paragraphe
.c-paragraph {
    font-size: var(--paragraph-font-size);
    line-height: var(--paragraph-line-height);
}

// L'introduction
.c-introduction  {
    font-size: var(--fz-25);
    font-weight: 500;
    line-height: 1.6em;
    color: rgb(var(--rgb-blue));
}

// La note
.c-note {
    font-family: var(--ff);
    font-size: var(--fz-14);
    line-height: 1.6em;
    color: rgb(var(--rgb-marine));
    white-space: normal;
}

// La citation
.c-quote {
    padding-left: 40px;
    border-left: 10px solid rgb(var(--rgb-orange));

    @media screen and (max-width: 1024px) {
        padding-left: 25px;
        border-width: 8px;
    }
    @media screen and (max-width: 768px)  {
        padding-left: 20px;
        border-width: 6px;
    }
    @media screen and (max-width: 500px)  {
        padding-left: 15px;
        border-width: 4px;
    }

    &, p {
        margin: 0;
        font-family: var(--ff);
        font-size: var(--fz-22);
        font-weight: 500;
        line-height: 1.6em;
    }
}


// LES LIENS ----------
// Le lien surligné
.c-underlined-link {
    font-size: var(--fz-17);
    font-weight: 700;
    line-height: 1.6em;
    color: rgb(var(--rgb-blue));
    text-decoration: none;

    span {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        color: inherit;
        box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-blue));
        transition: color 300ms var(--out-cubic), box-shadow 300ms var(--out-cubic);
    }

    &:hover span {
        color: rgb(var(--rgb-marine));
        box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-marine));
    }

    // Icones liens externes et téléchargement
    svg {
        margin-left: math.div(2, 17) + em;
        width: math.div(11, 17) + em;
        height: math.div(11, 17) + em;
        fill: rgb(var(--rgb-blue));
        transition: fill 300ms var(--out-cubic);
    }

    &:hover svg {
        fill: rgb(var(--rgb-marine));
    }

    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".zip"] {
        svg {
            width: math.div(12, 17) + em;
            height: math.div(12, 17) + em;
        }
    }
}


// LES BOUTONS ----------
// Le bouton plein et vide
.c-button {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 20px 40px;
    font-family: var(--ff);
    font-size: var(--fz-15);
    font-weight: 500;
    line-height: 1.3em;
    color: var(--button-color);
    background-color: var(--button-background-color);
    border: 1px solid var(--button-border-color);
    text-decoration: none;
    cursor: pointer;
    transition-property: color, background-color, border;
    transition-duration: 300ms;

    svg {
        content: "";
        translate: 0 -1px;
        width: var(--button-svg-dimension);
        height: var(--button-svg-dimension);
        flex-shrink: 0;
        display: inline-block;
    }

    &[target="_blank"] svg {
        --button-svg-dimension: 11px;
    }

    &[href$=".pdf"] svg,
    &[href$=".doc"] svg,
    &[href$=".zip"] svg {
        --button-svg-dimension: 12px;
    }

    &--svg-on-the-left {
        svg {
            margin-right: var(--button-svg-spacing, 10px);
        }
    }

    &--svg-on-the-right {
        svg {
            margin-left: var(--button-svg-spacing, 10px);
        }
    }

    &--svg-stroke {
        svg {
            stroke: var(--button-svg-color);
            stroke-width: var(--stroke-width, 1px);
            fill: none;
            transition: stroke 300ms;
        }
    }

    &--svg-fill {
        svg {
            fill: var(--button-svg-color);
            stroke: none;
            transition: fill 300ms;
        }
    }

    &--full-1 {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: rgb(var(--rgb-orange));
        --button-border-color: rgb(var(--rgb-orange));
        transition: all 0.3s;

        svg {
            --button-svg-color: rgb(var(--rgb-white));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-dark-orange));
            --button-border-color: rgb(var(--rgb-dark-orange));

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }

        &:disabled {
            --button-background-color: rgb(102, 102, 102);
            --button-border-color: rgb(102, 102, 102);
            cursor: not-allowed;
            transition: all 0.3s;

            &:hover {
                --button-background-color: rgb(102, 102, 102);
                --button-border-color: rgb(102, 102, 102);
            }
        }
    }

    &--full-2 {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: rgb(var(--rgb-marine));
        --button-border-color: rgb(var(--rgb-marine));

        svg {
            --button-svg-color: rgb(var(--rgb-white));
        }

        &:hover {
            --button-color: rgb(var(--rgb-marine));
            --button-background-color: rgb(var(--rgb-white));
            --button-border-color: rgba(var(--rgb-marine), 0.4);

            svg {
                --button-svg-color: rgb(var(--rgb-marine));
            }
        }
    }

    &--empty-1 {
        --button-color: rgb(var(--rgb-marine));
        --button-background-color: transparent;
        --button-border-color: rgb(var(--rgb-orange));

        svg {
            --button-svg-color: rgb(var(--rgb-marine));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-orange));
            --button-border-color: rgb(var(--rgb-orange));

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }
    }

    &--empty-2 {
        --button-color: rgb(var(--rgb-marine));
        --button-background-color: transparent;
        --button-border-color: rgba(var(--rgb-marine), 0.4);

        svg {
            --button-svg-color: rgb(var(--rgb-marine));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-marine));
            --button-border-color: rgb(var(--rgb-marine));

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }
    }
}


// LES LISTES ----------
// Liste désordonnée et ordonnée
.c-list {
    li {
        @extend .c-paragraph;

        p {
            margin: 0;
        }

        &:not(:first-child) {
            margin-top: 12px;
        }

        ol,
        ul {
            width: 100%;
            margin-top: 12px;
            margin-bottom: 0;

            li {
                &:not(:first-child) {
                    margin-top: 10px;
                }
            }
        }
    }

    &--unordered {
        --unordered-list-padding: 23px;
        @media screen and (max-width: 500px) { --unordered-list-padding: 18px; }

        & > li {
            width: calc(100% - var(--unordered-list-padding));
            position: relative;
            left: var(--unordered-list-padding);

            &::before {
                content: "";
                width: 8px;
                height: 8px;
                position: absolute;
                top: calc(var(--paragraph-line-height) / 2);
                translate: calc(0px - var(--unordered-list-padding)) -50%;
                rotate: 45deg;
                background-color: rgb(var(--rgb-orange));
                border: 1px solid rgb(var(--rgb-orange));
                box-sizing: border-box;
            }

            & > ul li {
                &::before {
                    width: 6px;
                    height: 6px;
                    border: 1px solid rgb(var(--rgb-marine));
                    background-color: transparent;
                    border-radius: 50%;
                }

                & > ul li::before {
                    width: 8px;
                    height: 8px;
                    rotate: 45deg;
                    background-color: rgb(var(--rgb-orange));
                    border: 1px solid rgb(var(--rgb-orange));
                    border-radius: 0;
                }
            }
        }
    }

    &--ordered {
        --ordered-list-padding: 23px;
        list-style: none;
        counter-reset: li;

        & > li {
            width: calc(100% - var(--ordered-list-padding));
            position: relative;
            left: var(--ordered-list-padding);

            &::before {
                content: counter(li) ".";
                counter-increment: li;
                display: inline-block;
                position: absolute;
                transform: translateX(calc(0px - var(--ordered-list-padding)));
                font-weight: 500;
                color: rgb(var(--rgb-marine));
            }

            ol > li {
                &::before {
                    content: counter(li, lower-alpha) ".";
                }

                ol > li {
                    @media screen and (min-width: 769px) { padding-left: 40px; }

                    &::before {
                        content: counter(li, lower-roman) ".";
                    }
                }
            }
        }
    }
}


// LES TABLEAUX ET ZONES ----------
// Le tableau
.c-table {
    // Ajouter cette variable si les bordures du tableau sont arrondies
    // --border-radius: 8px;
    overflow-x: auto;

    table {
        overflow-x: hidden;
        border-collapse: collapse;
        margin-right: 0 !important;

        tr {
            // Applique les styles pour toutes les cellules
            th,
            td {
                @extend .c-paragraph;

                padding: 12px 35px;
                border: 1px solid rgba(var(--rgb-marine), 0.4);

                & > :first-child {
                    margin-top: 0;
                }

                & > *:last-child {
                    margin-bottom: 0 !important;
                }

                @media screen and (max-width: 1024px) { padding: 12px 30px; }
                @media screen and (max-width: 768px)  { padding: 12px 25px; }
                @media screen and (max-width: 500px)  { padding: 12px 20px; }
            }

            // Applique les styles pour les cellules d'en-tête
            th,
            td.table-header {
                background-color: rgb(var(--rgb-marine));
                border-top-color: rgb(var(--rgb-marine));
                border-bottom-color: rgb(var(--rgb-marine));

                font-weight: 500;
                color: rgb(var(--rgb-white));
                text-align: left;

                &:first-child {
                    border-left-color: rgb(var(--rgb-marine));
                }

                &:last-child {
                    border-right-color: rgb(var(--rgb-marine));
                }
            }

            // Applique la couleur de background pour les cellules d'en-tête secondaire
            td.table-secondary-header {
                background-color: rgb(var(--rgb-lighter-blue));
            }
        }
    }

    // Ajustement des styles du tableaux avec des bordures arrondies
    &--rounded-borders {

        table {
            border-collapse: separate;
            border-radius: var(--border-radius);

            // Le border-radius de la première cellule dans le thead
            thead tr:first-child th:first-child {
                border-top-left-radius: var(--border-radius);
            }

            // Le border-radius de la dernière cellule dans le thead
            thead tr:first-child th:last-child {
                border-top-right-radius: var(--border-radius);
            }

            // Le border-radius de la première cellule dans la première rangée du tbody
            tbody:first-child tr:first-child th:first-child,
            tbody:first-child tr:first-child td:first-child {
                border-top-left-radius: var(--border-radius);
            }

            // Le border-radius de la dernière cellule dans la première rangée du tbody
            tbody:first-child tr:first-child th:last-child,
            tbody:first-child tr:first-child td:last-child {
                border-top-right-radius: var(--border-radius);
            }

            // Le border-radius de la première cellule dans la dernière rangée du tbody
            tr:last-child th:first-child:not(thead tr:last-child th:first-child),
            tr:last-child td:first-child:not(thead tr:last-child td:first-child) {
                border-bottom-left-radius: var(--border-radius);
            }

            // Le border-radius de la dernière cellule dans la dernière rangée du tbody
            tr:last-child th:last-child:not(thead tr:last-child th:last-child),
            tr:last-child td:last-child:not(thead tr:last-child td:last-child) {
                border-bottom-right-radius: var(--border-radius);
            }

            tr {
                // Ajustement des bordures à cause du border-collapse separate (Bordures horizontales en double)
                th,
                td {
                    &:not(:first-child) {
                        border-left: none;
                    }
                }
            }

            // Ajustement des bordures à cause du border-collapse separate (Bordures verticales en double)
            tr:not(:last-child) th,
            tr:not(:last-child) td {
                border-bottom: none;
            }
        }
    }
}

// Zone d'emphase
.c-emphasis {
    padding: var(--y-45) var(--x-40);
    border: 1px solid rgba(var(--rgb-marine), 0.4);

    & > :first-child {
        margin-top: 0;
    }

    & > :last-child {
        margin-bottom: 0
    }
}
