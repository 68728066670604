@use "../../base/typography";
@use "../../objects/x";

.c-overlay-share {
    --button-x-spacing: 20px;

    @media screen and (max-width: 600px) {
        --button-x-spacing: 10px;
    }
}

.c-overlay-share {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 100;

    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 520px;
        padding: 85px;
        background-color: rgb(var(--rgb-white));
        text-align: center;
        opacity: 0;
        pointer-events: none;
        transform: translateX(-50%);
        transition: opacity 800ms var(--in-out-quart), transform 800ms var(--in-out-quart);
        will-change: transform;

        @media screen and (max-width: 1200px) {
            width: 490px;
        }

        @media screen and (max-width: 1024px) {
            width: 400px;
            padding: 50px;
        }

        @media screen and (max-width: 600px) {
            right: 20px;
            left: 20px;
            width: auto;
            padding: 50px 20px;
            transform: none;
        }
    }

    &__title {
        @extend .c-medium-title;
        color: rgb(var(--rgb-marine));
        margin-bottom: 20px;
    }

    &__button-close {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        align-items: center;
    }

    &__button-close-x {
        @extend .o-x;
        width: 15px;
        height: 15px;

        --x-size: 15px;
        --x-thickness: 2px;
        --x-color: rgb(var(--rgb-marine));
    }

    &__button-close-x::before,
    &__button-close-x::after {
        height: 2px;
        background-color: rgb(var(--rgb-marine));
        transition: background-color 300ms;
    }

    &__button-close:hover &__button-close-x::before,
    &__button-close:hover &__button-close-x::after {
        background-color: rgb(var(--rgb-orange));
    }

    &__list {
        position: relative;
        display: flex;
        justify-content: center;
        margin: 16px calc(var(--button-x-spacing) / -2) 0;
    }

    &__link {
        position: relative;
        display: block;
        width: 45px;
        height: 45px;
        background-color: rgb(var(--rgb-lighter-blue));
        border-radius: 50%;
        cursor: pointer;
        transition: background 300ms;
        margin: 0 calc(var(--button-x-spacing) / 2);

        &:hover {
            background: rgb(var(--rgb-marine));
        }
    }

    &__svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        fill: rgb(var(--rgb-marine));
        transform: translate(-50%, -50%);
        transition: fill 300ms;
    }

    &__link:hover &__svg {
        fill: rgb(var(--rgb-white));
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 800ms;
        z-index: -1;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-share {
    .c-overlay-share {
        pointer-events: all;
        visibility: visible;

        &__container {
            opacity: 1;
            pointer-events: all;
            transform: translate(-50%, -50%);

            @media screen and (max-width: 600px) {
                transform: translateY(-50%);
            }
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-share.closing-overlay-share {
    .c-overlay-share {
        &__container {
            opacity: 0;
            transform: translateX(-50%);

            @media screen and (max-width: 600px) {
                transform: none;
            }
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0);
        }
    }
}
