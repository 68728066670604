.c-home-cms-content {
    &__top-section {
        margin-bottom: max(var(--y-50), 30px);

        @media screen and (max-width: 400px) { margin-bottom: 25px; }
    }

    &__surtitle {
        display: inline-block;
        margin-bottom: 15px;

        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-blue));
    }

    &__title {
        max-width: 750px;

        font-size: max(var(--fz-40), 2.5rem);
        font-weight: 700;
        line-height: 1.35em;
        color: rgb(var(--rgb-marine));
    }

    &__columns {
        --column-gap-x: 48px;
        @media screen and (max-width: 1200px) { --column-gap-x: 0px; }

        display: flex;
        flex-wrap: wrap;
        width: calc(100% + (var(--column-gap-x) * 2));
        margin-right: calc(0px - var(--column-gap-x));
        margin-left: calc(0px - var(--column-gap-x));
    }

    &__column {
        width: 50%;
        padding: 0 var(--column-gap-x);

        &:first-child:last-child {
            width: 100%;
        }

        @media screen and (max-width: 1200px) { width: 100%; }
    }

    &__column + &__column {
        @media screen and (max-width: 1200px) { margin-top: 50px; }
    }

    &__column-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__column-content {
        height: 100%;

        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    &__picture-container {
        width: 100%;
        height: 100%;
    }

    &__picture {
        position: relative;
        width: 100%;
        padding-top: 100%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__column-button {
        display: flex;
        width: 100%;
        margin-top: max(var(--y-75), 40px);

        @media screen and (max-width: 1200px) { justify-content: center; }
    }

    &__column-button2 {
        display: flex;
        gap: 10px;
        margin-top: max(var(--y-30), 40px);

        @media screen and (max-width: 1475px) { flex-direction: column; gap: 30px; a { width: fit-content; } }
        @media screen and (max-width: 1200px) { flex-direction: row; }
        @media screen and (max-width: 475px) { flex-direction: column; }
    }

    &__citation {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__columns + &__citation {
        margin-top: var(--y-115);
    }

    &__citation-content {
        position: relative;
        max-width: 500px;
        padding-right: 50px;
        padding-left: 50px;

        @media screen and (max-width: 800px) {
            padding-top: 15px;
            padding-right: 40px;
            padding-bottom: 15px;
            padding-left: 40px;
        }
    }

    &__citation-title {
        margin-bottom: 5px;

        font-size: var(--fz-24);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));

        @media screen and (max-width: 1200px) { font-size: max(var(--fz-20), 1.8rem); }
    }

    &__citation-text {
        display: block;

        font-size: 1.6rem;
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));

        &.--blue {
            font-weight: 500;
            color: rgb(var(--rgb-blue));
        }
    }

    &__citation-text + &__citation-text {
        margin-top: 10px;
    }

    &__citation-shape {
        position: absolute;
    }

    &__citation-shape:first-child {
        top: 0;
        left: 0;
    }

    &__citation-shape:last-child {
        right: 0;
        bottom: 0;
    }
}
