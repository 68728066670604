.c-aside {
    --vh-value: max(28vh, 168px);
}

.c-aside {
    width: 100%;
    height: 100%;
    padding-top: var(--header-height);
    background-color: rgb(var(--rgb-marine));

    &__container {
        height: 100%;
        padding-top: var(--vh-value);
    }

    &__sticky-content {
        position: sticky;
        top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: calc((var(--vh, 1vh) * 100) - var(--header-height) - var(--vh-value));
        min-height: calc(600px - var(--header-height) - var(--vh-value));
        padding-right: 25px;
        padding-bottom: 35px;
        padding-left: 25px;
    }

    &__list-item {
        position: relative;
        padding-left: 20px;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__link,
    &__button {
        display: block;

        text-align: left;

        span {
            transition: color 300ms;

            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.3em;
            color: rgba(var(--rgb-white), 0.6);
        }

        &.--active {
            &::before {
                content: "";
                position: absolute;
                top: 5px;
                left: 0;
                translate: 0 2px;
                width: 7px;
                height: 7px;
                background-color: rgb(var(--rgb-orange));
            }
        }

        &:hover span,
        &.--active span {
            color: rgb(var(--rgb-white));
        }
    }

    &__back-button {
        position: relative;
        display: flex;
        align-items: center;

        svg {
            rotate: 180deg;
            width: 12px;
            height: 12px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 3px;
        }

        svg:first-child {
            position: absolute;
            top: calc(50% - 1px);
            left: 0;
            translate: 0 -50%;
        }

        svg:last-child {
            transition: translate 200ms linear;
        }

        &:hover svg:last-child {
            translate: -6px 0;
        }

        span:first-child {
            translate: 0 1px;
            margin-right: 10px;
        }

        span:last-child {
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.6em;
            color: rgb(var(--rgb-white));
        }
    }

    &__scrolldown-button {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        width: 160px;
        height: 185px;
        padding-top: 70px;

        span {
            position: relative;
            z-index: 5;
        }

        span:not(:last-child) {
            font-size: 1.3rem;
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-white));
        }

        svg {
            rotate: 90deg;
            width: 12px;
            height: 12px;
            margin-top: 3px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 3px;
        }

        svg:first-child {
            position: absolute;
            top: 0;
            left: 50%;
            translate: -50% 0;
        }

        svg:last-child {
            transition: translate 200ms linear;
        }

        &:hover svg:last-child {
            translate: 0 6px;
        }
    }

    &__shape {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
