@use "../../base/typography";

.c-overlay-cookies {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 100;

    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% 0;
        display: flex;
        align-items: center;
        isolation: isolate;
        max-width: 620px;
        padding: 18px min(var(--x-25), 25px);
        background-color: rgb(var(--rgb-white));
        box-shadow: 0 4px 18px rgba(var(--rgb-black), 0.1);
        opacity: 0;
        pointer-events: none;
        transition: opacity 800ms var(--in-out-quart), translate 800ms var(--in-out-quart);

        @media screen and (max-width: 1200px) { flex-direction: column; }
        @media screen and (max-width: 700px) {
            right: var(--x-40);
            left: var(--x-40);
            translate: 0;
            align-items: center;
        }
    }

    &__text {
        flex: 1;
        font-size: var(--fz-15);
        line-height: 1.6em;

        @media screen and (max-width: 1200px) { text-align: center; }
    }

    &__buttons {
        margin-left: var(--x-45);

        @media screen and (max-width: 1200px) {
            margin-top: 20px;
            margin-left: 0;
        }
    }

    &__button-refuse {
        @extend .c-button;
        @extend .c-button--empty-2;
        margin-right: 6px;

        @media screen and (max-width: 400px) {
            margin-right: 0;
            margin-bottom: 6px;
        }
    }

    &__button-accept {
        @extend .c-button;
        @extend .c-button--full-2;
    }

    &__button-refuse,
    &__button-accept {
        @media screen and (max-width: 1200px) {
            padding: 18px 32px;
        }

        @media screen and (max-width: 400px) {
            justify-content: center;
            width: 100%;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 800ms;
        z-index: -1;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-cookies {
    .c-overlay-cookies {
        pointer-events: all;
        visibility: visible;

        &__container {
            translate: -50% -50%;
            opacity: 1;
            pointer-events: all;

            @media screen and (max-width: 700px) { translate: 0 -50%; }
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-cookies.closing-overlay-cookies {
    .c-overlay-cookies {
        &__container {
            translate: -50% 0;
            opacity: 0;

            @media screen and (max-width: 700px) { translate: 0; }
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0);
        }
    }
}
