.c-footer {
    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 150px;
        padding-top: 35px;
        padding-right: var(--x-50);
        padding-bottom: 20px;
        padding-left: calc(var(--aside-width) + var(--x-50));

        @media screen and (max-width: 900px) { padding-left: var(--x-50); }
        @media screen and (max-width: 700px) { height: auto; }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: var(--aside-width);
            background-color: rgb(var(--rgb-marine));

            @media screen and (max-width: 900px) { display: none; }
        }
    }

    &.--404 &__container,
    &.--500 &__container {
        padding-left: var(--x-50);

        &::before {
            display: none;
        }
    }

    &__flex {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &:first-child {
            @media screen and (max-width: 700px) {
                flex-direction: column;
                justify-content: initial;
            }
        }

    }

    &__flex + &__flex {
        margin-top: 35px;
    }

    &__left,
    &__right {
        display: flex;
        align-items: flex-end;

        @media screen and (max-width: 700px) {
            width: 100%;
            justify-content: space-between;
        }
    }

    &__left {
        @media screen and (max-width: 700px) {
            order: 2;
            margin-top: 35px;
        }
    }

    &__right {
        @media screen and (max-width: 700px) { order: 1; }
    }

    &__logo {
        &.--amq {
            width: 148px;
            height: 33px;
            margin-right: var(--x-60);

            @media screen and (max-width: 360px) {
                width: 138px;
                height: 29px;
            }
        }

        &.--quebec {
            width: 125px;
            height: 24px;

            @media screen and (max-width: 360px) {
                width: 115px;
                height: 22px;
            }
        }
    }

    &__link {
        margin-right: 65px;
    }

    &__button {
        display: flex;
        align-items: center;

        svg {
            translate: 0 -2px;
            rotate: -90deg;
            width: 16px;
            height: 16px;
            margin-left: 15px;
            stroke: rgb(var(--rgb-marine));
            stroke-width: 2px;
        }
    }

    &__link,
    &__button {
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }
    }

    &__link span,
    &__button span {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));
    }

    &__copyright-link {
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }
    }

    &__copyright-item,
    &__copyright-link span {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.6em;
    }
}
