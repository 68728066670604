/*
|--------------------------------------------------------------------------
| Les Font-face
|--------------------------------------------------------------------------
|
| La déclaration des polices de caractères.
|
| 100: Thin (Hairline)
| 200: Extra Light (Ultra)
| 300: Light
| 400: Normal (Regular, Book)
| 500: Medium
| 600: Semi Bold (Demi)
| 700: Bold
| 800: Extra Bold (Ultra)
| 900: Black (Heavy)
|
*/

@font-face {
    font-family: "DMSans";
    src: url("../fonts/DMSans-Bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DMSans";
    src: url("../fonts/DMSans-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "DMSans";
    src: url("../fonts/DMSans-Medium.ttf");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DMSans";
    src: url("../fonts/DMSans-MediumItalic.ttf");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "DMSans";
    src: url("../fonts/DMSans-Regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DMSans";
    src: url("../fonts/DMSans-Italic.ttf");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
