.c-button-hamburger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: rgb(var(--rgb-orange));
    border-radius: 50%;
    transition: background-color 300ms;
    font-size: 0;

    &__lines {
        width: 15px;
        height: 10px;
        position: relative;
    }

    &__line {
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: rgb(var(--rgb-white));
        opacity: 1;
        transition: rotate 400ms var(--out-cubic), opacity 300ms;
        display: block;
        position: absolute;

        &:first-child {
            top: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: calc(50% - 1px);
        }
        &:last-child {
            bottom: 0;
        }
    }
}
