@use '../base/typography';

.c-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: var(--header-height);
        padding-right: var(--x-70);
        padding-left: var(--x-25);

        @media screen and (max-width: 800px) { padding-right: var(--x-25); }
    }

    &__left,
    &__right {
        z-index: 10;
    }

    &__home-link {
        display: block;
        width: 172px;
        height: 37px;

        @media screen and (max-width: 900px) {
            width: 142px;
            height: 31px;
        }
    }

    &__logo {
        width: 100%;
        height: 100%;

        &.--mobile {
            display: none;
        }
    }

    &:not(.--home) &__logo {
        @media screen and (max-width: 900px) { display: none; }

        &.--mobile {
            @media screen and (max-width: 900px) { display: block; }
        }
    }

    &.--404 &__logo,
    &.--500 &__logo {
        display: none;

        &.--mobile {
            display: block;
        }
    }

    &__buttons {
        display: flex;

        @media screen and (max-width: 800px) { display: none; }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-fill;

        &:first-child {
            @extend .c-button--empty-1;
        }

        &:last-child {
            @extend .c-button--full-1;
        }
    }

    &.--home &__button {
        &:first-child span {
            color: rgb(var(--rgb-white));
        }
    }

    &__button + &__button {
        margin-left: 45px;
    }

    .c-button-hamburger {
        @media screen and (min-width: 801px) {
            display: none;
        }
    }
}
