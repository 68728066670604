.c-small-document-card {
    display: flex;
    position: relative;
    min-height: 76px;
    padding: 25px 40px 25px 0;
    align-items: center;
    text-decoration: none;
    border-bottom: 1px solid rgba(var(--rgb-marine), 0.4);
    cursor: pointer;

    @media screen and (max-width: 1024px) { padding-right: 30px; }
    @media screen and (max-width: 768px)  { padding-right: 20px; }
    @media screen and (max-width: 600px)  { padding-right: 10px; }

    &:first-child {
        border-top: 1px solid rgba(var(--rgb-marine), 0.4);
    }

    &__picture {
        flex-shrink: 0;
        width: 60px;
        height: 60px;
        margin-right: 30px;
        overflow: hidden;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__img {
        transform: scale(1);
        transition: transform 300ms;
    }

    &:hover &__img {
        transform: scale(1.1);
    }

    &__content {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__surtitle {
        display: block;
        margin-bottom: 12px;
        font-family: var(--ff);
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;
    }

    &:hover &__surtitle {
        color: rgb(var(--rgb-marine));
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-16);
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-blue));
    }

    &__svg-container {
        font-size: 0;
    }

    &__svg {
        translate: 0 -1px;
        width: 15px;
        height: 15px;
        fill: rgb(var(--rgb-marine));
        transition-property: stroke, fill;
        transition-duration: 300ms;
        transition-timing-function: linear;
    }

    &:hover &__svg {
        fill: rgb(var(--rgb-blue));
    }
}
