/*
|--------------------------------------------------------------------------
| Les variables de Font-families
|--------------------------------------------------------------------------
|
| Déclaration des variables de font-families du projet
|
*/

:root {
    --ff: DMSans, sans-serif;
}
