@use "../base/typography";

.c-page-title {
    padding-top: var(--header-height);
    background-color: rgb(var(--rgb-lighter-blue));

    &.--with-image {
        position: relative;
        isolation: isolate;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            height: 18.5%;
            background-color: rgb(var(--rgb-white));
        }
    }

    &__container {
        padding-top: var(--y-110);
        padding-right: var(--x-160);
        padding-bottom: var(--y-70);
        padding-left: var(--x-160);
    }

    &.--with-image &__container {
        padding-bottom: 0;
    }

    &__title {
        @extend .c-huge-title;

        color: rgb(var(--rgb-marine));
    }

    &__picture-container,
    &__picture {
        position: relative;
        width: 100%;
    }

    &__picture {
        padding-top: 38.7%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__title + &__picture-container {
        margin-top: 50px;
    }
}
