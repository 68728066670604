.o-section {
    &.--cms-content {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &.--small-documents {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &.--documents {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &.--gallery {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &.--rubrics {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &.--form {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &.--home-cms-content {
        padding-top: var(--y-115);
        padding-right: var(--x-160);
        padding-bottom: max(var(--y-140), 110px);
        padding-left: var(--x-160);
    }

    &.--challenges {
        padding-top: var(--y-115);
        padding-right: var(--x-160);
        padding-bottom: var(--y-115);
        padding-left: var(--x-160);
    }

    &.--page-title + section {
        margin-top: 65px;
    }
}

.o-section {
    position: relative;

    &.--with-aside {
        display: flex;
    }

    &.--content-with-aside {
        width: calc(100% - var(--aside-width));

        @media screen and (max-width: 900px) { width: 100%; }
    }

    &.--aside {
        width: var(--aside-width);

        @media screen and (max-width: 900px) { display: none; }
    }

    &.--challenges,
    &.--challenges + &.--home-cms-content {
        background-color: rgb(var(--rgb-lighter-blue));
    }

    &.--challenges + &.--home-cms-content {
        padding-top: 0;
        padding-bottom: max(var(--y-65), 50px);
    }

    &.--home-showcase + &.--home-cms-content {
        padding-bottom: 85px;
    }

    &.--home-cms-content + &.--home-cms-content {
        padding-bottom: max(var(--y-115), 55px);
        background-color: rgb(var(--rgb-lighter-blue));
    }

    .c-blue-square {
        position: absolute;
        top: 0;
        right: 0;
        translate: 0 -50%;
        width: clamp(105px, 16vw, 235px);
    }
}
