@use 'sass:math';

:root {
    // Générales
    --calendar-header-background-color: rgb(var(--rgb-white));
    --calendar-header-color: rgb(var(--rgb-marine));
    --calendar-header-color-hover: rgba(var(--rgb-marine), 0.5);

    --calendar-main-background-color: rgb(var(--rgb-white));
    --calendar-main-color: rgb(var(--rgb-marine));
    --calendar-main-day-cell-size: 3.6rem;
    @media screen and (max-width: 1024px) { --calendar-main-day-cell-size: 3.4rem; }
    --calendar-main-month-year-cell-size: calc(var(--calendar-main-day-cell-size) * 1.7);

    // Dates des mois précédents ou suivant (.prev, .next)
    --calendar-day-previous-or-next-color: rgba(var(--rgb-marine), 0.5);
    --calendar-day-previous-or-next-background-color: transparent;
    --calendar-day-previous-or-next-dot-or-underline-background-color: rgba(var(--rgb-marine), 0.5);
    --calendar-month-year-previous-or-next-color: rgb(var(--rgb-marine));


    // Date avec événement (:not(.disabled))
    --calendar-day-color: rgb(var(--rgb-marine));
    --calendar-day-background-color: transparent;
    --calendar-day-border-color: transparent;
    --calendar-day-dot-or-underline-background-color: rgb(var(--rgb-marine));
    --calendar-month-year-color: rgb(var(--rgb-marine));

    --calendar-day-color-hover: rgb(var(--rgb-marine));
    --calendar-day-background-color-hover: rgb(var(--rgb-lighter-blue));
    --calendar-day-border-color-hover: transparent;
    --calendar-day-dot-or-underline-background-color-hover: rgb(var(--rgb-marine));
    --calendar-month-year-color-hover: rgba(var(--rgb-marine), 0.5);


    // Date d'aujourd'hui
    --calendar-today-color: rgb(var(--rgb-marine));
    --calendar-today-background-color: transparent;
    --calendar-today-border-color: rgb(var(--rgb-marine));
    --calendar-today-dot-or-underline-background-color: rgb(var(--rgb-marine));

    --calendar-today-color-hover: rgb(var(--rgb-marine));
    --calendar-today-background-color-hover: rgb(var(--rgb-lighter-blue));
    --calendar-today-border-color-hover: rgb(var(--rgb-lighter-blue));
    --calendar-today-dot-or-underline-background-color-hover: rgb(var(--rgb-marine));


    // Date sélectionnée
    --calendar-day-active-color: rgb(var(--rgb-white));
    --calendar-day-active-background-color: rgb(var(--rgb-orange));
    --calendar-day-active-border-color: rgb(var(--rgb-orange));
    --calendar-day-active-dot-or-underline-background-color: rgb(var(--rgb-white));

    --calendar-day-active-color-hover: rgb(var(--rgb-marine));
    --calendar-day-active-background-color-hover: rgb(var(--rgb-lighter-blue));
    --calendar-day-active-border-color-hover: rgb(var(--rgb-lighter-blue));
    --calendar-day-active-dot-or-underline-background-color-hover: rgb(var(--rgb-marine));
}

// Calendrier en dropdown dans champ date
.datepicker-dropdown {
    position: absolute;
    top: calc(100% + 15px) !important;
    left: 0 !important;
    width: 350px;
    background-color: white;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 300ms, visibility 0ms ease 300ms;
    z-index: 100;
    border-radius: 10px;

    @media screen and (max-width: 425px) {
        width: 100%;
    }

    &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transition: opacity 300ms;
    }

    .datepicker-picker .datepicker-header {
        @media screen and (max-width: 425px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .datepicker-picker .datepicker-main {
        --calendar-main-background-color: white;

        @media screen and (max-width: 425px) {
            padding-top: 28px;
            padding-bottom: 18px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .datepicker-picker .day::after {
        display: none;
    }
}

.datepicker-picker {
    display: inline-block;
    padding: 22px;

    span {
        display: block;
        flex: 1;
        border: 0;
        cursor: default;
        text-align: center;
        -webkit-touch-callout: none;
        user-select: none;
    }

    .datepicker-header {
        background-color: var(--calendar-header-background-color);
        padding-bottom: 22px;
        border-bottom: 1px solid rgb(var(--rgb-marine));
    }

    .datepicker-main {
        padding-top: 22px;
        background-color: var(--calendar-main-background-color);
    }
}

.datepicker-controls {
    display: flex;

    .button {
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 0;
        cursor: pointer;
        white-space: nowrap;
        font-family: var(--ff);
        font-size: 1.7rem;
        font-weight: 500;
        color: var(--calendar-header-color);
        transition: color 300ms;
        translate: 0 2px;

        &:hover {
            color: var(--calendar-header-color-hover);
        }

        &:focus,
        &:active {
            outline: none;
        }

        &[disabled] {
            cursor: not-allowed;
        }
    }

    .view-switch {
        flex: auto;
    }

    .prev-btn {
        justify-content: flex-start;
    }

    .next-btn {
        justify-content: flex-end;
    }

    .prev-btn,
    .next-btn {
        width: percentage(math.div(1, 7));
        translate: 0;

        >svg {
            width: 12px;
            height: 12px;
            fill: var(--calendar-header-color);
            transition: fill 300ms;
        }

        &:hover >svg {
            fill: var(--calendar-header-color-hover);
        }

        &.disabled {
            visibility: hidden;
        }
    }

    .prev-btn>svg {
        transform: rotate(90deg);
    }

    .next-btn>svg {
        transform: rotate(-90deg);
    }
}

.datepicker-view {
    display: flex;

    .days-of-week {
        display: flex;
        margin-bottom: 10px;
    }

    .dow {
        flex-basis: percentage(math.div(1, 7));
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--calendar-main-color);
    }
}

.datepicker-grid {
    display: flex;
    flex-wrap: wrap;
}

.datepicker-cell {
    height: var(--calendar-main-day-cell-size);
    line-height: var(--calendar-main-day-cell-size);

    &.day {
        position: relative;
        font-size: 1.4rem;
        font-weight: 500;
        color: var(--calendar-main-color);
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: var(--calendar-main-day-cell-size);
            height: var(--calendar-main-day-cell-size);
            background-color: transparent;
            border: 1px solid transparent;
            border-radius: 3px;
            transition: background-color 300ms, border-color 300ms;
            z-index: -1;
        }

        &::after {
            content: "";
            position: absolute;
            top: 8px;
            left: calc(50% + 12px);
            transform: translateX(-50%);
            width: 5px;
            height: 5px;
            background-color: transparent;
            border-radius: 50%;
            transition: background-color 0.3s;
            z-index: 1;
        }
    }

    &.month,
    &.year,
    &.decade {
        font-size: var(--fz-16);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .1em;
        color: var(--calendar-main-color);
        transition: color 300ms;
    }

    &.disabled {
        cursor: default;

        &::after {
            display: none;
        }
    }

    // Date avec événement (:not(.disabled))
    &:not(.disabled) {
        cursor: pointer;

        &::after {
            //background-color: var(--calendar-day-dot-or-underline-background-color);
            border-color: var(--calendar-day-border-color);
        }

        &:hover {
            &::before {
                background-color: var(--calendar-day-background-color-hover);
                border-color: var(--calendar-day-border-color-hover);
            }
        }
    }

    // Date d'aujourd'hui
    &.day.today:not(.selected) {
        color: var(--calendar-today-color);

        &::before {
            background-color: var(--calendar-today-background-color);
            border-color: var(--calendar-today-border-color);
        }

        &::after {
            //background-color: var(--calendar-today-dot-or-underline-background-color);
        }

        &:hover:not(.disabled) {
            color: var(--calendar-today-color-hover);

            &::before {
                background-color: var(--calendar-today-background-color-hover);
                border-color: var(--calendar-today-border-color-hover);
            }

            &::after {
                //background-color: var(--calendar-today-dot-or-underline-background-color-hover);
            }
        }
    }

    // Date sélectionnée
    &.day.selected {
        color: var(--calendar-day-active-color);

        &::before {
            background-color: var(--calendar-day-active-background-color);
            border-color: var(var(--calendar-day-active-border-color));
        }

        &::after {
            //background-color: var(--calendar-day-active-dot-or-underline-background-color);
        }

        &:hover {
            color: var(--calendar-day-active-color-hover);

            &::before {
                background-color: var(--calendar-day-active-background-color-hover);
                border-color: var(var(--calendar-day-active-border-color-hover));
            }

            &::after {
                //background-color: var(--calendar-day-active-dot-or-underline-background-color-hover);
            }
        }
    }

    // Dates des mois précédents ou suivant (.prev, .next)
    &.day.prev,
    &.day.next {
        color: var(--calendar-day-previous-or-next-color);

        &::before {
            background-color: var(--calendar-day-previous-or-next-background-color);
        }

        &::after {
            //background-color: var(--calendar-day-previous-or-next-dot-or-underline-background-color);
        }
    }

    &.month,
    &.year,
    &.decade {

        &.prev,
        &.next {
            color: var(--calendar-month-year-previous-or-next-color);
        }

        &.selected,
        &:hover {
            color: var(--calendar-month-year-color-hover);
        }
    }
}

.datepicker-view {
    .days .datepicker-cell {
        flex-basis: percentage(math.div(1, 7));
    }

    &.months .datepicker-cell,
    &.years .datepicker-cell,
    &.decades .datepicker-cell {
        flex-basis: 25%;
        height: var(--calendar-main-month-year-cell-size);
        line-height: var(--calendar-main-month-year-cell-size);
    }
}

.datepickerWrapper {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    width: 350px;
    background-color: white;
    opacity: 0;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
    visibility: hidden;
    pointer-events: none;
    z-index: 100;
    transition: opacity 300ms, visibility 0ms ease 300ms;

    @media screen and (max-width: 425px) {
      width: 100%;
    }

    &.show {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transition: opacity 300ms;
    }
}
