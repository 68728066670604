.c-challenges {
    --challenges-gap-x: 25px;
    @media screen and (max-width: 1100px) { --challenges-gap-x: 0px; }
    --challenges-gap-y: 40px;
    @media screen and (max-width: 600px) { --challenges-gap-y: 20px; }

}

.c-challenges {
    &__top-section {
        margin-bottom: max(var(--y-50), 30px);

        @media screen and (max-width: 400px) { margin-bottom: 25px; }
    }

    &__surtitle {
        display: inline-block;
        margin-bottom: 15px;

        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-blue));
    }

    &__title {
        font-size: max(var(--fz-40), 2.5rem);
        font-weight: 700;
        line-height: 1.35em;
        color: rgb(var(--rgb-marine));
    }

    &__subtitle {
        font-size: max(var(--fz-30), 2rem);
        font-weight: 600;
        line-height: 1.2em;
        color: rgb(var(--rgb-marine));
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--challenges-gap-x) * 2));
        margin-right: calc(0px - var(--challenges-gap-x));
        margin-bottom: calc(0px - var(--challenges-gap-y));
        margin-left: calc(0px - var(--challenges-gap-x));
    }

    &__list-item {
        width: 50%;
        padding: 0 var(--challenges-gap-x);
        margin-bottom: var(--challenges-gap-y);

        @media screen and (max-width: 1100px) { width: 100%; }
    }
}
