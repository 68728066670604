@use "../../abstract/placeholders";

.c-large-document-card {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 125px;
    padding: 30px 75px 27px 30px;
    background-color: rgb(var(--rgb-lighter-blue));
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    @media screen and (max-width: 1024px) { padding-right: 50px; }
    @media screen and (max-width: 768px)  {
        min-height: 100px;
        padding-right: 30px;
    }

    &__picture {
        flex-shrink: 0;
        width: 90px;
        height: 90px;
        margin-right: 30px;
        overflow: hidden;

        // Ajouter cette ligne si il y a un border-radius sur l'image, pour prévenir un problème sur Mac
        // @extend %border-radius;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__content {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__surtitle {
        display: block;
        margin-bottom: 12px;
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;
    }

    &:hover &__surtitle {
        color: rgb(var(--rgb-marine));
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-25);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-blue));
    }

    &__svg-container {
        translate: 0 -1px;
        font-size: 0;
    }

    &__svg {
        width: 20px;
        height: 20px;
        fill: rgb(var(--rgb-marine));
        transition-property: stroke, fill;
        transition-duration: 300ms;
        transition-timing-function: linear;
    }

    &:hover &__svg {
        fill: rgb(var(--rgb-blue));
    }
}
