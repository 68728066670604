.c-home-showcase {
    &__container {
        position: relative;
    }

    &__picture-container,
    &__picture {
        position: relative;
        width: 100%;
    }

    &__picture-container::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        pointer-events: none;
        background: linear-gradient(0deg, rgba(39, 98, 177, 0.4) 0%, rgba(39, 98, 177, 0.16) 100%);
    }

    &__picture {
        padding-top: 41%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__text-blocks {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        padding-right: var(--x-160);
        padding-left: var(--x-160);
        margin-top: -136px;

        @media screen and (max-width: 1100px) {
            flex-direction: column;
            margin-top: -45px;
            align-items: center;
            justify-content: initial;
        }
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 424px;
        height: 272px;
        padding: 40px;
        background-color: rgb(var(--rgb-blue));

        @media screen and (max-width: 1200px) { width: 374px; }
        @media screen and (max-width: 1100px) {
            width: 100%;
            height: initial;
            min-height: 164px;
            padding: 30px;
        }
        @media screen and (max-width: 500px) { padding: 25px; }
    }

    &__text-block + &__text-block {
        margin-left: var(--x-50);

        @media screen and (max-width: 1100px) {
            margin-left: 0;
            margin-top: 15px;
        }
    }

    &__title {
        margin-bottom: 30px;

        font-size: max(var(--fz-24), 1.8rem);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__false-link {
        display: block;
        align-self: flex-end;

        span {
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.6em;
            color: rgb(var(--rgb-white));
            box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-orange));
            transition: box-shadow 300ms var(--out-cubic);
        }
    }

    &__text-block:hover &__false-link span {
        box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-white));
    }
}
