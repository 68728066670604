/*
|--------------------------------------------------------------------------
| Root
|--------------------------------------------------------------------------
|
| Déclaration de tout ce qui doit se retrouver dans le sélecteur :root.
|
*/

@use "../abstract/mixins" as *;

:root {
    // Générer les variables CSS des font-sizes
    @include fontSizes(
        "14", "15", "16", "17", "18", "20", "21", "22", "24", "25", "30", "40", "60",
    );

    // Générer les variables CSS des espacements
    @include spacings(
        "x-25", "x-40", "x-45", "x-50", "x-60", "x-70", "x-80", "x-160", "x-220", "x-420",
        "y-20", "y-25", "y-30", "y-35", "y-40", "y-45", "y-50", "y-55", "y-60", "y-65", "y-70", "y-75", "y-80", "y-85", "y-90", "y-100", "y-110", "y-115", "y-140", "y-150",
    );

    // Variables CSS

    // Texte
    --paragraph-font-size: var(--fz-16);
    --paragraph-line-height: 1.6em;

    // Crédits photo / Tooltip formulaire
    --tooltip-dimensions: 18px;

    // Header
    --header-height: 90px;

    // Aside
    --aside-width: 220px;
}
