@use "../base/typography";

.c-maintenance {

    height: 100vh;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__background-image {
        position: fixed;
        z-index: 1;
        height: 100.1vh;
        width: 100.1vw;
        object-fit: cover;
    }

    &__logo {
        padding-top: 40px;
        width: 225px;
    }

    &__container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 40%;
        text-align: center;
    }

    &__title {
        color: rgb(var(--rgb-white));
        font-size: var(--fz-60);
        font-family: var(--ff-2);
        font-weight: 500;
        line-height: 1.75em;
        max-width: 800px;

        @media (max-width: 400px) {
            font-size: var(--fz-40);
        }
    }

    &__text {
        color: rgb(var(--rgb-white));
        font-size: var(--fz-40);
        font-family: var(--ff-2);
        font-weight: 400;

        @media (max-width: 400px) {
            font-size: var(--fz-30);
        }
    }

    &__footer {
        position: absolute;
        bottom: 40px;
        color: rgb(var(--rgb-white));
        font-size: var(--fz-17);
        font-family: var(--ff-2);
        font-weight: 600;
    }

}
