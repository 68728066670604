.c-overlay-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    isolation: isolate;
    visibility: hidden;
    pointer-events: none;
    transition: visibility 0ms 600ms;

    &__container {
        position: absolute;
        top: 0;
        right: -100%;
        bottom: 0;
        transition: right 600ms var(--in-out-cubic);
        width: 100%;
        padding-top: 80px;
        padding-right: 35px;
        padding-bottom: 80px;
        padding-left: 35px;
        background-color: rgb(var(--rgb-marine));
    }

    &__list, &__scroll-to-list {
        translate: 30px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        width: 100%;
        height: 50%;
        opacity: 0;
    }

    &__scroll-to-list {
        justify-content: flex-end;
    }

    &__list-item:not(:last-child) {
        margin-bottom: 20px;
    }

    &__link {
        display: block;

        font-size: 2rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }

        svg {
            width: 18px;
            height: 18px;
            margin-left: 5px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
        }
    }
}

/*
|--------------------------------------------------------------------------
| SHOW OVERLAY
|--------------------------------------------------------------------------
*/
.show-overlay-menu {
    .c-overlay-menu {
        pointer-events: all;
        visibility: visible;
        transition: visibility 0ms 0ms;

        &__container {
            right: 0;
        }

        &__list, &__scroll-to-list {
            translate: 0;
            opacity: 1;
            transition: translate 400ms 500ms var(--out-cubic), opacity 600ms 500ms;
        }
    }

    .c-header {
        z-index: initial;

        &__right {
            position: relative;
            z-index: 500;
        }
    }

    .c-button-hamburger {
        &__line {
            &:first-child,
            &:last-child {
                opacity: 0;
            }
            &:nth-child(2){
                rotate: 45deg;
            }
            &:nth-child(3) {
                rotate: -45deg;
            }
        }
    }
}


/*
|--------------------------------------------------------------------------
| CLOSING OVERLAY
|--------------------------------------------------------------------------
*/
.show-overlay-menu.closing-overlay-menu {
    .c-overlay-menu {
        &__container {
            right: -100%;
        }

        &__list {
            translate: 30px 0;
            opacity: 0;
            transition: translate 400ms 0ms var(--out-cubic), opacity 600ms 0ms;
        }
    }

    .c-header {
        z-index: initial;

        &__right {
            position: relative;
            z-index: 500;
        }
    }
}
