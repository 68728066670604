@use '../base/typography';

.c-home-banner {
    &__container {
        position: relative;
        z-index: 1;
        overflow: hidden;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        min-height: 600px;
    }

    &__shape {
        position: absolute;
        top: -25px;
        right: 0;
        z-index: 5;
        width: 100%;
        min-width: 1220px;
        pointer-events: none;

        @media screen and (max-width: 600px) { display: none; }

        &.--mobile {
            top: 0;
            display: block;
            min-width: 380px;
            @media screen and (min-width: 601px) { display: none; }
        }
    }

    &__picture-container,
    &__picture {
        width: 100%;
        height: 100%;
    }

    &__picture-container {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 5;
            background: linear-gradient(359.39deg, rgba(11, 31, 57, 0.4) 1.69%, rgba(11, 31, 57, 0) 99.48%);
            pointer-events: none;
        }
    }

    &__text-block {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        padding-top: calc(var(--header-height) + var(--y-90));
        padding-left: clamp(50px, var(--x-420), 420px);

        @media screen and (max-width: 600px) { padding-right: clamp(50px, var(--x-420), 420px); }
        @media screen and (max-width: 340px) {
            padding-right: clamp(30px, var(--x-420), 420px);
            padding-left: clamp(30px, var(--x-420), 420px);
        }

        @media screen and (max-height: 700px) { padding-top: calc(var(--header-height) + var(--y-50)); }
    }

    &__title {
        font-size: max(var(--fz-60), 40px);
        line-height: 1.3em;
        font-weight: 400;
        color: rgb(var(--rgb-white));

        span {
            display: block;
        }

        span:nth-child(2) {
            font-weight: 800;
        }

        span:last-child {
            padding-left: min(var(--x-220), 220px);

            @media screen and (max-width: 1400px) { padding-left: var(--x-160); }
            @media screen and (max-width: 1300px) { padding-left: max(var(--x-80), 75px); }
        }
    }

    &__subtitle {
        @extend .c-medium-title;

        padding-left: min(var(--x-220), 220px);
        margin-top: 20px;

        font-size: min(var(--fz-30), 30px);
        font-weight: 400;
        color: rgb(var(--rgb-white));

        @media screen and (max-width: 1400px) { padding-left: var(--x-160); }
        @media screen and (max-width: 1300px) { padding-left: var(--x-80); }
        @media screen and (max-width: 1200px) { padding-left: var(--x-50);}
        @media screen and (max-width: 600px) { font-size: min(var(--fz-25), 18px); }
    }

    &__buttons {
        display: flex;
        padding-left: min(var(--x-220), 220px);
        margin-top: clamp(80px, var(--y-90), 90px);

        @media screen and (max-width: 1400px) { padding-left: var(--x-160); }
        @media screen and (max-width: 1300px) { padding-left: var(--x-80); }
        @media screen and (max-width: 1200px) { padding-left: 50px; }
        @media screen and (max-width: 600px) {
            flex-direction: column;
            align-self: center;
            width: fit-content;
            padding-left: 0;
        }

        @media screen and (max-height: 700px) { margin-top: clamp(60px, var(--y-70), 60px); }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--full-1;
    }

    &__button + &__button {
        margin-left: 25px;

        @media screen and (max-width: 600px) {
            margin-top: 12px;
            margin-left: 0;
        }
    }

    &__scrolldown-button {
        position: absolute;
        right: var(--x-50);
        bottom: var(--y-55);
        z-index: 10;
        width: 17px;
        height: 38px;
        transition: opacity 300ms;

        @media screen and (max-width: 600px) { display: none; }

        @media screen and (max-width: 1200px) and (max-height: 640px) { display: none; }

        &:hover {
            opacity: 0.5;
        }

        svg {
            width: 100%;
            height: 100%;
            fill: rgb(var(--rgb-white));
            transition: translate 700ms ease 400ms;

            animation-name: bouncing;
            animation-duration: 1.6s;
            animation-iteration-count: infinite;
        }

        &:hover svg {
            animation-play-state: paused;
        }

        @keyframes bouncing {
            0%   { translate: 0 0; }
            50% { translate: 0 10px; }
            100% { translate: 0 0; }
        }
    }

    &__logo-container {
        position: absolute;
        bottom: var(--y-55);
        left: var(--x-60);
        z-index: 10;

        @media screen and (max-width: 600px) {
            right: 25px;
            bottom: 30px;
            left: initial;
        }

        @media screen and (max-width: 1200px) and (max-height: 640px) {
            right: 25px;
            bottom: 30px;
            left: initial;
        }
    }

    &__logo {
        width: 237px;
        height: 78px;

        @media screen and (max-width: 600px) {
            width: 117px;
            height: 39px;
        }

        @media screen and (max-width: 1200px) and (max-height: 640px) {
            width: 117px;
            height: 39px;
        }
    }
}

// Animations
.c-home-banner {
    &__shape,
    &__text-block {
        translate: 0 -50px;
        opacity: 0;
        transition: translate 700ms cubic-bezier(0.33, 1, 0.68, 1) 800ms, opacity 400ms ease 800ms;
    }

    &[data-fire] &__shape,
    &[data-fire] &__text-block {
        translate: 0;
        opacity: 1;
    }
}
