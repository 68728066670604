.c-challenge-card {
    &__picture-container,
    &__picture {
        position: relative;
        width: 100%;
    }

    &__picture-container::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        pointer-events: none;
        background-color: rgba(var(--rgb-black), 0.2);
        opacity: 0;
        transition: opacity 300ms;
    }

    &:hover &__picture-container::before {
        opacity: 1;
    }

    &__picture {
        padding-top: 42.5%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__text-block {
        padding-top: max(var(--y-35), 30px);
        padding-right: max(var(--x-40), 35px);
        padding-bottom: max(var(--y-35), 30px);
        padding-left: max(var(--x-40), 35px);
        background-color: rgb(var(--rgb-white));
    }

    &__title {
        font-size: max(var(--fz-20), 1.8rem);
        font-weight: 700;
        line-height: 1.35em;
        color: rgb(var(--rgb-marine));
    }

    &__bottom-section {
        display: flex;
        align-items: center;
        margin-top:max(var(--x-25), 25px);
        height: 60px;
    }

    &__logo {
        width: 60px;
        height: 60px;

        @media screen and (max-width: 500px) {
            width: 54px;
            height: 54px;
        }
    }

    &__city {
        font-size: max(var(--fz-18), 1.7rem);
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));
    }

    &__logo + &__city {
        margin-left: 20px;

        @media screen and (max-width: 400px) { margin-left: 18px; }
    }

    &__done {
        position: absolute;
        background-color: rgb(var(--rgb-orange));
        top: 0;
        left: 0;
        z-index: 100;
        padding: 14px 18px 14px 18px;

        @media screen and (max-width: 1400px) { padding: 12px 16px 12px 16px; }
        @media screen and (max-width: 1100px) { padding: 13px 17px 13px 17px; }
        @media screen and (max-width: 600px) { padding: 12px 16px 12px 16px; }
        @media screen and (max-width: 400px) { padding: 10px 14px 10px 14px; }
    }

    &__done-text {
        font-size: 15px;
        line-height: 1.3em;
        font-weight: 700;
        color: rgb(var(--rgb-white));
    }

    &__date_limit_container {
        height: 37px;
        margin-bottom: 30px;
    }

    &__date_limit_line1, &__date_limit_line2 {
        font-size: 13.5px;
        line-height: 1.3em;
        color: rgb(var(--rgb-marine));
    }

    &__date_limit_line2 {
        font-weight: 700;
        color: rgb(var(--rgb-orange));
    }
}
