/*
|--------------------------------------------------------------------------
| Les variables de couleur
|--------------------------------------------------------------------------
|
| Déclaration des variables de couleurs du projet
|
*/

:root {
    // #000000 - Black
    --rgb-black: 0, 0, 0;

    // #FFFFFF - White
    --rgb-white: 255, 255, 255;

    // #E0192C - Red
    --rgb-red: 224, 25, 44;

    // #061027 - Marine
    --rgb-marine: 6, 16, 39;

    // #D86133 - Orange
    --rgb-orange: 216, 97, 51;

    // #C03600 - Dark orange
    --rgb-dark-orange: 192, 54, 0;

    // #EDF0F4 - Lighter Blue
    --rgb-lighter-blue: 237, 240, 244;

    // #DDE0E5 - Light Blue
    --rgb-light-blue: 221, 224, 229;

    // #2661B5 - Blue
    --rgb-blue: 38, 97, 181;

    // #4BA091 - Turquoise
    --rgb-turquoise: 75, 160, 145;

    // #8F2800 - Burnt Orange
    --rgb-burnt-orange: 143, 40, 0;

    // #818A92 - Light Gray
    --rgb-light-gray: 129, 138, 146;

    // #5B6670 - Gray
    --rgb-gray: 91, 102, 112;

    // #ACBBCF - Blue Gray
    --rgb-blue-gray: 172, 187, 207;
}
