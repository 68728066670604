.c-share-button {
    display: inline-flex;
    align-items: center;
    font-family: var(--ff);
    font-size: 1.5rem;
    font-weight: 500;
    color: rgb(var(--rgb-marine));
    text-decoration: none;
    cursor: pointer;
    transition: color 300ms;

    &:hover {
        color: rgb(var(--rgb-blue));
    }

    &__icon {
        translate: 0 -2px;
        width: 12px;
        height: 12px;
        margin-left: 10px;
        fill: rgb(var(--rgb-marine));
        transition: fill 300ms;
    }

    &:hover &__icon {
        fill: rgb(var(--rgb-blue));
    }
}
