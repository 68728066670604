.c-form-label {
    display: block;
    margin-bottom: 8px;
    font-size: var(--fz-17);
    font-weight: 500;
    line-height: 1.6em;
    color: rgb(var(--rgb-marine));

    span {
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: rgb(var(--rgb-red));
        border-radius: 50%;
        vertical-align: super;
        margin-bottom: 0.2em;
        margin-left: 2px;
    }
}

.c-form-required-dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: rgb(var(--rgb-red));
    border-radius: 50%;
    vertical-align: super;
    margin-bottom: 0.2em;
    margin-left: 2px;
}
