.c-checkbox-field {
    --checkbox-field-title-color: rgb(var(--rgb-marine));
    --checkbox-field-label-color: rgb(var(--rgb-marine));
    --checkbox-field-dot-color: rgb(var(--rgb-white));
    --checkbox-field-box-background-color: transparent;
    --checkbox-field-box-background-color-active: rgb(var(--rgb-marine));
    --checkbox-field-box-border-color: rgba(var(--rgb-marine), 0.4);
    --checkbox-field-box-border-color-active: rgb(var(--rgb-marine));
}

.c-checkbox-field {
    --checkbox-field-label-font-size: var(--fz-16);
    position: relative;

    &__header {
        margin-bottom: var(--y-nus-30);
    }

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &__label {
        display: flex;
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &:last-child {
            margin-bottom: 0;
        }

        &--disabled {
            opacity: 0.6;
            pointer-events: none;
        }
    }

    &__text {
        flex: 1;
        font-family: var(--ff);
        font-size: var(--checkbox-field-label-font-size);
        line-height: 1.5em;
        color: var(--checkbox-field-label-color);
        order: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &__box {
        position: relative;
        width: 18px;
        height: 18px;
        margin-top: calc(((var(--checkbox-field-label-font-size) * 1.5) - 18px) / 2);
        margin-right: 15px;
        background-color: var(--checkbox-field-box-background-color);
        border: 1px solid var(--checkbox-field-box-border-color);
        border-radius: 3px;
        order: 1;
        transition-property: background-color, border-color;
        transition-duration: 225ms;

        &::before {
            content: "";
            width: 12px;
            height: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 225ms;
            background: url("../medias/images/icons/checkmark.svg") no-repeat center/contain;
        }
    }

    &__input:checked ~ &__box {
        background-color: var(--checkbox-field-box-background-color-active);
        border-color: var(--checkbox-field-box-border-color-active);

        &::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &--inline &__options {
        margin-top: -20px;
    }

    &--inline &__label {
        display: inline-flex;
        margin: 20px 30px 0 0;
    }
}
